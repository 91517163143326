<template>
  <div class="swperTop">
    <!-- 顶部工具栏 -->
    <backButtonVue></backButtonVue>
    <!-- 顶部图片 -->
    <div
      v-bind:style="{ backgroundImage: 'url(' + top_img + ')' }"
      class="top-img"
    >
      <div class="title-box">
        <h1 style="width: 100%" v-if="ZHtitle === '赛默飞世尔科技与赛默飞中国临床诊断解决方案介绍'">
          赛默飞世尔科技<br>与赛默飞中国临床诊断解决方案介绍
        </h1>
        <h1 v-else>{{ ZHtitle }}</h1>
        <span>{{ ENtitle }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import backButtonVue from "./backButton.vue";
export default {
  name: 'swipe-top',
  components: {
    backButtonVue,
  },
  props: {
    ZHtitle: String,
    ENtitle: String,
    top_img: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.swperTop {
  height: 30%;
  .top-img {
    width: 100%;
    height: 77%;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    .title-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #fff;
      width: 100%;
      margin: 2.7vw 2.7vw;
      font-family: 'FZZZH',serif;
      h1 {
        font-size: 5.5vw;
        margin: 5px 0;
        width: 55%;
      }
      span {
        font-size: 2vw;
      }
    }
  }
}
</style>
