<template>
  <div class="back">
    <div class="container">
      <img @click="back()" class="backImg" name="arrow-left" src="../assets/img/return.png" alt="" />
<!--      <img id="dropdown-btn"  class="backImg" name="arrow-left" src="../assets/img/menu1.png" alt="" />-->
      <div style="color: #000000">
        <span style="font-weight: bolder;font-size: 1.2rem;margin: 0 8px">|</span>
        <span id="dropdown-btn" @click="onclickMenu" class="backImg" style="font-weight: bolder;font-size: 1.1rem;">导航</span>
      </div>
    </div>
    <div class="logo-header">
      <img class="logo-box" src="../assets/img/logo1.png" alt="" />
    </div>
    <div id="dropdown-menu">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <img style="flex: 1;width: 0px;" src="../assets/img/living.png" alt="">
        <a @click="onSkip(4)" style="flex: 3;">品牌介绍</a>
      </div>
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/move.png" alt="">
        <a @click="onSkip(5)" style="flex: 3;">活动信息</a>
      </div>
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/contact.png" alt="">
        <a @click="onSkip(6)" style="flex: 3;">联系我们</a>
      </div>
      <!-- <div style="display: flex;align-items: center;">
         <img style="flex: 1;width: 0px;" src="../assets/img/territory.png" alt="">
        <a  @click="onSkip(7)" style="flex: 3;">应用领域</a>
      </div>
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/product.png" alt="">
        <a  @click="onSkip(8)" style="flex: 3;">产品介绍</a>
      </div> -->
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/means.png" alt="">
        <a @click="onSkip(10)" style="flex: 3;">资料中心</a>
      </div>
<!--      <div style="display: flex;align-items: center;">-->
<!--        <img style="flex: 1;width: 0px;" src="../assets/img/service.png" alt="">-->
<!--        <a @click="onSkip(9)" style="flex: 3;">应用服务</a>-->
<!--      </div>-->
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/document.png" alt="">
        <a @click="onSkip(11)" style="flex: 3;">文献指南</a>
      </div>
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/Classroom.png" alt="">
        <a @click="onSkip(12)" style="flex: 3;">在线课堂</a>
      </div>
      <div style="display: flex;align-items: center;">
        <img style="flex: 1;width: 0px;" src="../assets/img/learning.png" alt="">
        <a @click="onSkip(13)" style="flex: 3;">学术分享</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'back-buttonVue',
  methods: {
    back() {
      this.$router.go(-1);
    },
    onclickMenu() {
      const dropdownBtn = document.getElementById("dropdown-btn");
      const dropdownMenu = document.getElementById("dropdown-menu");
      dropdownMenu.style.display = (dropdownMenu.style.display === "block") ? "none" : "block";
      // 点击页面任意位置，隐藏下拉框
      document.addEventListener('click', e => {
        if (!dropdownBtn.contains(e.target)) {
          dropdownMenu.style.display = 'none';
        }
      })
    },
    onSkip(id) {
      let query = {
        mid: id,
      };
      switch (id) {
        case 9: // 应用服务
        case 10: // 资料中心
        case 11: // 文献指南
        case 12: // 在线课堂
        case 13: // 学术分享
          this.$router.push({ path: "/secondaryPages", query });
          break;
        case 7: // 应用领域
        case 8: // 产品介绍
          this.$router.push({ path: "/secondaryPagesYYLY", query });
          break;
        case 4: // 品牌介绍
          this.$router.push({ path: "/secondaryPagesPPJS", query });
          break;
        case 5: // 活动信息
          this.$router.push({ path: "/secondaryPagesHDXX", query });
          break;
        case 6: // 联系我们
          this.$router.push({ path: "/secondaryPagesLXWM", query });
          break;
        default:
          console.log("NOT FOUNT");
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.back {
  height: 23%;
  display: flex;
  flex: content;
  justify-content: space-between;
  color: red;
  //margin: 0 8px;
  padding: 0 8px;
  background-color: white;
  .container {
    display: flex;
    align-items: center;
    //justify-content: center;
    width: 75%;
    text-align: start;
    /* 可以添加此行代码使图片在水平方向上居中 */
    .backImg {
      height: 55%;
      background-color: transparent;
      // background-color:0 0 0 0.5 ;
    }
  }

  .logo-header {
    width: 35%;
    .logo-box {
      width: auto;
      height: 100%;
      top: 0;
      right: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  span {
    transform: scale(0.9);
  }
}

#dropdown-btn {
  // background-color: #e7e7e7;
  color: black;
  //padding: 8px 16px;
  border: none;
  cursor: pointer;
}

#dropdown-btn:hover {
  background-color: #ddd;
}


#dropdown-menu {
  width: 20%;
  display: none;
  margin-top: 95px;
  color: #fff;
  //padding: 5px 10px;
  position: absolute;
  left: 50px;
  z-index: 1;
  background-color: rgb(57, 57, 57);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
}

#dropdown-menu a {
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.6rem;
  border-bottom: 1px solid rgb(64, 64, 64);

}

#dropdown-menu div {
  text-align: center;

}

#dropdown-menu a:hover {
  background-color: #ddd;
}

#dropdown-menu::before {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  /* 与正方形顶部距离为 20px */
  left: 40%;
  /* 与正方形中心水平对齐 */
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid rgb(57, 57, 57);
  /* 与正方形颜色相同 */
}
</style>
